#exampleonediv .simulationParameterLabel {
  text-align: left!important;
  width: 100%!important;
}
#exampleonediv table {
  min-width: 30%;
  max-width: 100%;
  width: 500px;
}

.summaryTable th{
  text-align: center;
}

.reducedLineHeight, .reducedLineHeight *{
  line-height: 0.9rem;
  font-size: 0.80rem;
}
.reducedLineHeight input{

  height: 0.85rem;
  font-size: 0.8rem;
}

.bottomRight {
  position: fixed;
  bottom: 0;
  right: 0;
}