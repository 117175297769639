
.printableDiv .table {
  font-size: 0.8rem; /* increase font size */
}
@media print {
  .printableDiv {
    margin-top: 1in; /* add a white margin on top */
    margin-left: 0.5in; /* increase left margin */
    margin-right: 0.5in; /* increase right margin */
  }
  .printableDiv .table {
    font-size: 1.2rem; /* increase font size */
  }
}